import { fromJS, Map } from "immutable";

import { push } from "Reducers/app";
import logger from "Libs/logger";

const LOAD_MERGE_START = "app/environmentMerge/load_merge_start";
const LOAD_MERGE_SUCCESS = "app/environmentMerge/load_merge_success";
const LOAD_MERGE_FAILURE = "app/environmentMerge/load_merge_failure";

export const merge = (
  organizationDescriptionId,
  projectDescriptionId,
  environmentDescriptionId
) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOAD_MERGE_START });

    try {
      const environment = getState().environment.getIn(
        [
          "data",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId
        ],
        {}
      );
      const merge = await environment.merge();

      dispatch({
        type: LOAD_MERGE_SUCCESS,
        payload: merge,
        meta: {
          organizationDescriptionId,
          environmentDescriptionId,
          projectDescriptionId
        }
      });

      dispatch(
        push(
          "/:organizationDescriptionId/:projectDescriptionId/:environmentDescriptionId",
          {
            organizationDescriptionId,
            projectDescriptionId,
            environmentDescriptionId
          }
        )
      );
    } catch (err) {
      logger(err, {
        action: "merge",
        payload: merge,
        meta: {
          organizationDescriptionId,
          environmentDescriptionId,
          projectDescriptionId
        }
      });
      dispatch({
        type: LOAD_MERGE_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export default function environmentMergeReducer(state = new Map(), action) {
  switch (action.type) {
    case LOAD_MERGE_START:
      return state.set("loading", true).remove("errors");
    case LOAD_MERGE_SUCCESS:
      return state
        .set("loading", false)
        .setIn(
          [
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId,
            action.meta.environmentDescriptionId
          ],
          fromJS(action.payload)
        )
        .remove("errors");
    case LOAD_MERGE_FAILURE:
      return state.set("loading", false).set("errors", fromJS(action.payload));
    default:
      return state;
  }
}
