import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router";

import InfoIcon from "Icons/InfoIcon";

const Layout = styled.span`
  position: relative;
  cursor: pointer;
  height: 24px;
  width: 24px;
  display: inline-block;
  margin-left: 8px;
  svg {
    height: auto;
    width: 100%;
    > path {
      fill: #4a495e;
    }
  }
  &:hover,
  &:focus,
  &:focus-within {
    outline: none;
    .dialog {
      display: inline-block;
    }
  }
`;

const Dialog = styled.div`
  background: #fff;
  border-radius: 2px;
  width: 300px;
  box-shadow: 0 6px 24px rgba(152, 160, 171, 0.4);
  padding: 16px;
  line-height: 22px;
  font-size: 14px;
  font-weight: normal;
  text-transform: none;
  z-index: 9;
  letter-spacing: 0;
  a {
    color: ${props => props.theme.links};
    text-decoration: none;
    padding: 0 8px;
    margin: 0 -8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    box-sizing: border-box;
    display: inline-block;
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      outline: none;
      border: 1px solid ${props => props.theme.buttonHover};
      border-radius: 4px;
      box-shadow: 0 0 2px ${props => props.theme.buttonHover};
      text-decoration: none;
      margin: -1px -9px -1px;
    }
    &:active {
      border: none;
      box-shadow: none;
      text-decoration: underline;
      margin: 0 -8px;
    }
  }
  a + a {
    margin-left: 16px;
    &:focus {
      margin-left: 15px;
    }
    &:active {
      margin-left: 16px;
    }
  }
`;

const DialogWrapper = styled.div`
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  padding-top: 8px;
  z-index: 9;
  &.align-left {
    left: 0;
  }
  &.align-right {
    left: auto;
    right: 0;
  }
  &.align-center {
    left: 12px;
    margin-left: -150px;
  }
`;

const Title = styled.div`
  font-weight: 600;
  margin-bottom: 4px;
  white-space: normal;
  color: #38485e;
`;

const Text = styled.div`
  margin-bottom: 8px;
  white-space: normal;
  color: #4a495e;
`;

const InfoDialog = ({
  className,
  title,
  text,
  linkText,
  linkText2,
  to,
  toLocal = false,
  to2,
  to2Local = false,
  icon,
  align = "left"
}) => (
  <Layout
    className={`info-dialog${className ? " " + className : ""}`}
    tabIndex="0"
  >
    {icon ? icon : <InfoIcon />}
    {(title || text || to) && (
      <DialogWrapper className={`dialog${align ? " align-" + align : ""}`}>
        <Dialog>
          {title && (
            <React.Fragment>
              <Title>{title}</Title>
            </React.Fragment>
          )}
          {text && <Text>{text}</Text>}
          {to && (
            <Link
              to={to}
              rel={!toLocal ? "noopener noreferrer" : ""}
              target={!toLocal ? "_blank" : ""}
            >
              {linkText ? linkText : to}
            </Link>
          )}
          {to2 && (
            <Link
              to={to2}
              rel={!to2Local ? "noopener noreferrer" : ""}
              target={!to2Local ? "_blank" : ""}
            >
              {linkText2 ? linkText2 : to2}
            </Link>
          )}
        </Dialog>
      </DialogWrapper>
    )}
  </Layout>
);

InfoDialog.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  linkText: PropTypes.string,
  linkText2: PropTypes.string,
  to: PropTypes.string,
  toLocal: PropTypes.bool,
  to2: PropTypes.string,
  to2Local: PropTypes.bool,
  icon: PropTypes.node,
  align: PropTypes.string,
  className: PropTypes.string
};

export default InfoDialog;
