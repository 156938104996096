import styled from "styled-components";

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  min-height: 32px;
  box-sizing: border-box;
  button + button {
    margin-left: 16px;
    &:focus {
      margin-left: 15px !important;
    }
    &:active {
      margin-left: 16px !important;
    }
  }
  .outline {
    position: absolute;
    right: 0;
  }
  &.modal-buttons {
    flex-direction: row-reverse;
    button {
      margin: 0 0 0 16px;
      &:focus {
        margin: 0 -1px 0 15px;
      }
    }
  }
`;
