import { Map, fromJS, List } from "immutable";
import localForage from "localforage";

import {
  immutableListToMap,
  getOrganizationDescriptionId,
  getOrganizationDescriptionIdFromProject
} from "Libs/utils";
import logger from "Libs/logger";

import { PROJECT_ID_FIELD, ORGANIZATION_ID_FIELD } from "Constants/constants";
import { loadProjectActivities } from "Reducers/activity";
import { loadRegions } from "Reducers/project/region";

const LOAD_PROJECTS_START = "app/projects/load_start";
export const LOAD_PROJECTS_SUCCESS = "app/projects/load_success";
const LOAD_PROJECTS_FAILURE = "app/projects/load_failure";

const LOAD_LAST_PROJECTS_START = "app/last_projects/load_start";
const LOAD_LAST_PROJECTS_SUCCESS = "app/last_projects/load_success";
const LOAD_LAST_PROJECTS_FAILURE = "app/last_projects/load_failure";

const LOAD_PROJECT_START = "app/project/load_start";
const LOAD_PROJECT_SUCCESS = "app/project/load_success";
const LOAD_PROJECT_FAILURE = "app/project/load_failure";

export const UPDATE_PROJECT_START = "app/project/update_start";
export const UPDATE_PROJECT_SUCCESS = "app/project/update_success";
export const UPDATE_PROJECT_FAILURE = "app/project/update_failure";

const loadProjectSuccess = project => {
  return { type: LOAD_PROJECT_SUCCESS, payload: project };
};

export const loadProjects = () => {
  return async (dispatch, getState) => {
    dispatch({ type: LOAD_PROJECTS_START });
    dispatch(loadRegions());

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const projects = await client.getProjects();

      const organizations = immutableListToMap(
        getState().app.getIn(["me", "organizations"], new List()) || new List(),
        "id",
        ORGANIZATION_ID_FIELD
      );

      dispatch({
        type: LOAD_PROJECTS_SUCCESS,
        payload: {
          projects
        },
        meta: {
          organizations
        }
      });
    } catch (err) {
      logger(err, {
        action: "projects_load"
      });
      dispatch({ type: LOAD_PROJECTS_FAILURE, error: true, payload: err });
    }
  };
};

export const loadLastVisitedProjects = () => {
  return async (dispatch, getState) => {
    const project = getState().project;

    if (!project) {
      return false;
    }

    let lastVisitedProjects = project.get("lastVisitedProjects");

    if (lastVisitedProjects && lastVisitedProjects.size) {
      return false;
    }

    try {
      dispatch({ type: LOAD_LAST_PROJECTS_START });

      lastVisitedProjects = await localForage.getItem("lastVisitedProjects");

      dispatch({
        type: LOAD_LAST_PROJECTS_SUCCESS,
        payload: lastVisitedProjects
      });
    } catch (err) {
      dispatch({ type: LOAD_LAST_PROJECTS_FAILURE });
    }
  };
};

const subscribe = project => {
  return async (dispatch, getState) => {
    const projectSubscription = await project.subscribe();

    const platformLib = await import("platformsh-client");
    const models = platformLib.models;

    const activityReducerModule = await import("Reducers/activity");
    const loadActivitySuccess = activityReducerModule.loadActivitySuccess;

    projectSubscription.addEventListener(
      "resource/activity",
      message => {
        if (!message.data) {
          return false;
        }

        const activityRawMessage = JSON.parse(message.data);
        dispatch(
          loadActivitySuccess(
            new models.Activity(
              activityRawMessage.data,
              activityRawMessage.data._links.self.href
            )
          )
        );
      },
      false
    );

    projectSubscription.addEventListener(
      "resource/project",
      message => {
        if (!message.data) {
          return false;
        }

        const projectRawMessage = JSON.parse(message.data);

        dispatch(
          loadProjectSuccess(
            new models.Project(
              projectRawMessage.data,
              projectRawMessage.data._links.self.href
            )
          )
        );
      },
      false
    );

    const environmentReducerModule = await import("Reducers/environment");
    const loadEnvironmentFromEventSuccess =
      environmentReducerModule.loadEnvironmentFromEventSuccess;

    projectSubscription.addEventListener(
      "resource/environment",
      message => {
        if (!message.data) {
          return false;
        }

        const environmentRawMessage = JSON.parse(message.data);

        const organizationDescriptionId = getOrganizationDescriptionId(
          getState,
          environmentRawMessage.data.project
        );

        dispatch(
          loadEnvironmentFromEventSuccess(
            new models.Environment(
              environmentRawMessage.data,
              environmentRawMessage.data._links.self.href
            ),
            organizationDescriptionId
          )
        );
      },
      false
    );
  };
};

export const loadProject = projectId => {
  return async (dispatch, getState) => {
    dispatch({ type: LOAD_PROJECT_START });
    try {
      const projectReducer = getState().project || new Map();
      let project = projectReducer.getIn(["data", projectId]);

      // Get the project id from the project description id in the
      // Project loaded from /me
      let projectMe = getState()
        .app.getIn(["me", "projects"])
        .find(p => p.get(PROJECT_ID_FIELD) === projectId);

      if (!projectMe) {
        const platformLib = await import("Libs/platform");
        const client = platformLib.default;
        const { entities, request } = platformLib;

        projectMe = await request(
          `${client.getConfig().api_url}/platform/projects/${projectId}`,
          "GET"
        );

        project = await entities.Project.get({}, projectMe.endpoint);

        if (!project) {
          throw "project.notfound";
        }
      }

      if (!project) {
        const platformLib = await import("Libs/platform");
        const client = platformLib.default;

        project = await client.getProject(projectMe.get("id"));

        if (!project) {
          throw "project.notfound";
        }
      }

      const organizations = immutableListToMap(
        getState().app.getIn(["me", "organizations"], new List()) || new List(),
        "id",
        ORGANIZATION_ID_FIELD
      );

      let lastVisitedProjects = getState().project.get("lastVisitedProjects");

      if (!lastVisitedProjects) {
        lastVisitedProjects =
          (await localForage.getItem("lastVisitedProjects")) || [];
      }

      lastVisitedProjects = fromJS(lastVisitedProjects);

      const currentProjectIndex = lastVisitedProjects.findIndex(p => {
        return p.get("id") === project.id;
      });

      let newLastVisitedProjects = lastVisitedProjects;

      if (currentProjectIndex !== -1) {
        newLastVisitedProjects = newLastVisitedProjects.delete(
          currentProjectIndex
        );
      }

      newLastVisitedProjects = newLastVisitedProjects.unshift(
        fromJS({ ...project })
      );

      if (newLastVisitedProjects.size > 10) {
        newLastVisitedProjects = newLastVisitedProjects.pop();
      }

      // @todo: Refactor to get rid of instances where we can't use .toJS().
      let formattedProjectMe = projectMe;
      if (typeof projectMe.toJS === "function") {
        formattedProjectMe = projectMe.toJS();
      }

      dispatch({
        type: LOAD_PROJECT_SUCCESS,
        payload: project,
        meta: {
          organizations,
          lastVisitedProjects: newLastVisitedProjects,
          projectMe: formattedProjectMe
        }
      });

      dispatch(
        loadProjectActivities(
          projectId,
          getOrganizationDescriptionIdFromProject(formattedProjectMe)
        )
      );

      localForage.setItem("lastVisitedProjects", newLastVisitedProjects.toJS());

      dispatch(subscribe(project));
    } catch (err) {
      logger(err, {
        action: "project_load",
        projectId
      });
      dispatch({ type: LOAD_PROJECT_FAILURE, error: true, payload: err });
    }
  };
};

export const updateProject = (
  organizationDescriptionId,
  projectDescriptionId,
  data
) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_PROJECT_START });

    try {
      const projectMe = getState()
        .app.getIn(["me", "projects"])
        .find(p => p.get(PROJECT_ID_FIELD) === projectDescriptionId);

      const projectReducer = getState().project || new Map();
      let project = projectReducer.getIn([
        "data",
        organizationDescriptionId,
        projectDescriptionId
      ]);

      if (!project) {
        return dispatch({
          type: UPDATE_PROJECT_FAILURE,
          error: true,
          payload: "project does not exist"
        });
      }

      const organizations = immutableListToMap(
        getState().app.getIn(["me", "organizations"], new List()) || new List(),
        "id",
        ORGANIZATION_ID_FIELD
      );
      const result = await project.update(data);

      dispatch({
        type: UPDATE_PROJECT_SUCCESS,
        payload: result.getEntity(),
        meta: { organizations, projectMe: projectMe.toJS() }
      });
    } catch (err) {
      logger(err, {
        action: "project_update",
        organizationDescriptionId,
        projectDescriptionId,
        data
      });
      dispatch({ type: UPDATE_PROJECT_FAILURE, error: true, payload: err });
    }
  };
};

export default function projectReducer(state = new Map(), action) {
  switch (action.type) {
    case LOAD_PROJECTS_START:
    case UPDATE_PROJECT_START:
      return state.set("loading", true);
    case LOAD_PROJECT_START:
      return state.delete("projectLoadingError").set("loading", true);
    case LOAD_LAST_PROJECTS_SUCCESS:
      return state.set("lastVisitedProjects", fromJS(action.payload || []));
    case LOAD_PROJECTS_SUCCESS:
      return state.set("loading", false).set(
        "data",
        fromJS(
          action.payload.projects.reduce((organizationsProjects, project) => {
            const organizationId = getOrganizationDescriptionIdFromProject(
              project
            );
            if (!organizationsProjects[organizationId]) {
              organizationsProjects[organizationId] = {};
            }

            organizationsProjects[organizationId][
              project[PROJECT_ID_FIELD]
            ] = fromJS(project);

            return organizationsProjects;
          }, {})
        )
      );
    case UPDATE_PROJECT_SUCCESS:
    case LOAD_PROJECT_SUCCESS:
      return state
        .setIn(
          [
            "data",
            getOrganizationDescriptionIdFromProject(action.meta.projectMe),
            action.payload[PROJECT_ID_FIELD]
          ],
          action.payload.updateLocal({
            ...action.payload.data,
            owner_info: action.meta.projectMe.owner_info,
            plan_uri: action.meta.projectMe && action.meta.projectMe.plan_uri
          })
        )
        .set("lastVisitedProjects", action.meta.lastVisitedProjects)
        .set("loading", false)
        .setIn(
          ["orgByProjectId", action.payload[PROJECT_ID_FIELD]],
          getOrganizationDescriptionIdFromProject(action.meta.projectMe)
        )
        .delete("projectLoadingError");
    case LOAD_PROJECT_FAILURE:
    case LOAD_PROJECTS_FAILURE:
      return state
        .set("loading", false)
        .set("projectLoadingError", fromJS(action.payload));
    default:
      return state;
  }
}
