import { fromJS, Map } from "immutable";
import { request } from "Libs/platform";
import config from "../../constants/api_config";
import logger from "Libs/logger";

const LOAD_REGIONS_START = "app/regions/load_regions_start";
const LOAD_REGIONS_SUCCESS = "app/regions/load_regions_success";
const LOAD_REGIONS_FAILURE = "app/regions/load_regions_failure";

export const loadRegions = () => {
  return async dispatch => {
    dispatch({ type: LOAD_REGIONS_START });

    try {
      const regionsEndpoint = `${config.api_url}/v1/regions`;
      const allRegions = await request(regionsEndpoint, "GET");

      const availableRegions = allRegions.regions.filter(
        region => region.available == true // Double equals because ome available keys are strings, and some aren't.
      );

      const byUrl = allRegions.regions.reduce((urls, region) => {
        const url = region.endpoint.replace("https://", "").replace("/api", "");
        urls[url] = region.label;
        return urls;
      }, {});

      dispatch({
        type: LOAD_REGIONS_SUCCESS,
        payload: availableRegions,
        meta: {
          byUrl
        }
      });
    } catch (err) {
      logger(err);
      dispatch({ type: LOAD_REGIONS_FAILURE, error: true, payload: err });
    }
  };
};

export default function regionReducer(state = new Map(), action) {
  switch (action.type) {
    case LOAD_REGIONS_START:
      return state.set("loading", true);
    case LOAD_REGIONS_SUCCESS:
      return state
        .set("loading", false)
        .set(
          "data",
          fromJS(
            action.payload.reduce((accumulator, current) => {
              if (!accumulator[current.zone]) {
                accumulator[current.zone] = [current];
                return accumulator;
              }

              accumulator[current.zone].push(current);
              return accumulator;
            }, {})
          )
        )
        .set("byUrl", fromJS(action.meta.byUrl))
        .set("errors", false);
    case LOAD_REGIONS_FAILURE:
    default:
      return state;
  }
}
