import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router";
import { injectIntl } from "react-intl";

import CloseIcon from "Icons/CloseIcon";

const Layout = styled.span`
  a {
    width: 32px;
    height: 32px;
    filter: ${props => props.filter};
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    &:hover,
    &:focus {
      outline: none;
      background-color: #ededed;
    }
    &:active {
      background-color: transparent;
    }
  }
  svg {
    height: auto;
    width: 100%;
    > g > g {
      fill: #98a0ab;
    }
  }
  &:hover svg > g > g {
    fill: #4a495e;
  }
`;

const CloseBtn = ({ onClick, filter, id, color, intl, floatRight, toLink }) => (
  <Layout style={{ float: floatRight && "right" }}>
    {!toLink ? (
      <Link
        id={id}
        aria-label={intl.formatMessage({ id: "close" })}
        role="button"
        className="close"
        onClick={e => {
          e.preventDefault();
          onClick();
        }}
        filter={filter}
        tabIndex="0"
        href=""
      >
        <CloseIcon color={color} />
      </Link>
    ) : (
      <Link
        id={id}
        aria-label={intl.formatMessage({ id: "close" })}
        role="button"
        className="close"
        to={toLink}
        filter={filter}
        tabIndex="0"
        href="#"
      >
        <CloseIcon color={color} />
      </Link>
    )}
  </Layout>
);

CloseBtn.propTypes = {
  onClick: PropTypes.func,
  filter: PropTypes.string,
  id: PropTypes.string,
  intl: PropTypes.object,
  color: PropTypes.string,
  floatRight: PropTypes.bool,
  toLink: PropTypes.string
};

export default injectIntl(CloseBtn);
