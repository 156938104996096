import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StateIconLayout = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  width: 24px;
  svg > g > g {
    fill: ${props => props.theme.error};
  }
`;

const ErrorIcon = ({ color = "none", className = "icon" }) => (
  <StateIconLayout className={className}>
    <svg
      className="icon-error"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      alt="Error"
    >
      <defs />
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-1142.000000, -1371.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g transform="translate(1140.000000, 1369.000000)">
            <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M13,17 L11,17 L11,15 L13,15 L13,17 Z M13,13 L11,13 L11,7 L13,7 L13,13 Z" />
          </g>
        </g>
      </g>
    </svg>
  </StateIconLayout>
);

ErrorIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string
};

export default ErrorIcon;
