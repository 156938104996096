import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import CloseBtn from "Components/CloseBtn";
import Heading3 from "Components/styleguide/Heading3";

const ModalTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;
  .close {
    margin: -10px -10px 0 0;
  }
  h3 {
    margin: 0;
    display: flex;
    align-items: center;
    .title {
      display: inline-block;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
  .with-copy {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    h2 {
      margin-right: 20px;
    }
  }
  &.fixed {
    position: fixed;
    margin-left: -45px;
    padding: 20px 43px;
    box-sizing: border-box;
    margin-top: -45px;
    background: #fff;
    margin-bottom: 0;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  .copy-to-clipboard {
    cursor: pointer;
  }
  .icon {
    height: 32px;
    width: 32px;
    padding: 5px;
    display: inline-flex;
    background: #c9d0e4;
    border-radius: 5px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
  &.no-title {
    margin: 0;
    float: right;
  }
`;

const ModalTitleBar = ({
  title,
  copyText = "",
  closeModal,
  fixed = false,
  id,
  icon
}) => (
  <ModalTitleWrapper
    className={`modal-title-bar${fixed ? " fixed" : ""}${
      title ? "" : " no-title"
    }`}
  >
    {title && (
      <div className={`title-wrapper${copyText ? " with-copy" : ""}`}>
        <Heading3 id={`${id}-title`}>
          {icon && <span className="icon">{icon}</span>}
          <span className="title">{title}</span>
        </Heading3>
      </div>
    )}
    {closeModal && (
      <div id={`${id}-close-btn`}>
        <CloseBtn
          onClick={closeModal}
          onKeyUp={e => e.keyCode === 13 && closeModal()}
        />
      </div>
    )}
  </ModalTitleWrapper>
);

ModalTitleBar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  copyText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  closeModal: PropTypes.func,
  fixed: PropTypes.bool,
  icon: PropTypes.node
};
export default ModalTitleBar;
