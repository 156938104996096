import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import ErrorIcon from "Icons/ErrorIcon";

const ErrorWrapper = styled.div`
  color: ${props => props.theme.error};
  margin: 0 0 10px 0;
  padding: 20px;
  border: 1px solid ${props => props.theme.error};
  width: 100%;
  box-sizing: border-box;
  &.inline {
    margin: 0 0 0 10px;
    position: absolute;
    left: 100%;
    top: 40px;
  }
  &.inline.no-label {
    top: auto;
    height: 100%;
    display: flex;
    align-items: center;
    line-height: 100%;
  }
  .icon {
    float: left;
  }
  .message {
    margin-left: 50px;
  }
  p {
    margin: 0 0 15px;
    &:last-child {
      margin: 0;
    }
  }
`;

class Error extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { children, className } = this.props;
    return (
      <ErrorWrapper
        className={`error-alert${className ? " " + className : ""}`}
        role="alert"
      >
        <ErrorIcon />
        <div className="message">{children}</div>
      </ErrorWrapper>
    );
  }
}

Error.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Error;
