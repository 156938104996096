import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import styled from "styled-components";
import { LiveMessage } from "react-aria-live";

import ModalTitleBar from "Components/ModalTitleBar";
import CopyButton from "Components/CopyButton";
import ButtonWrapper from "Components/ButtonWrapper";

const ModalBody = styled.div`
  width: 100%;
  box-sizing: border-box;
  &.fixed-header {
    padding-top: 50px;
    &.with-copy-link {
      padding-top: 135px;
    }
  }
`;

class ModalWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.modalClassOverride = this.modalClassOverride.bind(this);
    this.overlayClassOverride = this.overlayClassOverride.bind(this);

    this.state = {
      modalCustomClass: "modal-after-open",
      overlayCustomClass: "overlay-after-open"
    };
  }

  modalClassOverride(modalClass) {
    if (modalClass) {
      this.setState({
        modalCustomClass: modalClass + " " + this.state.modalCustomClass
      });
    }
    return this.state.modalCustomClass;
  }

  overlayClassOverride(overlayClass) {
    if (overlayClass) {
      this.setState({
        overlayCustomClass: overlayClass + " " + this.state.overlayCustomClass
      });
    }
    return this.state.overlayCustomClass;
  }

  render() {
    const {
      id,
      title,
      closeModal,
      isOpen,
      fixed = false,
      copyText = "",
      modalClass = "",
      overlayClass = "",
      onRequestClose,
      shouldCloseOnOverlayClick,
      announceTitle
    } = this.props;

    let completeModalClasses = "modal-after-open";
    modalClass ? (completeModalClasses += " " + modalClass) : "";

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose ? onRequestClose : closeModal}
        contentLabel="Modal opened"
        className={{
          base: "modal",
          afterOpen: completeModalClasses,
          beforeClose: "modal-before-close"
        }}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        overlayClassName={{
          base: "overlay",
          afterOpen: overlayClass
            ? overlayClass + " overlay-after-open"
            : "overlay-after-open",
          beforeClose: "overlay-before-close"
        }}
        role="dialog"
        aria={{
          labelledby: id,
          describedby: "modal-body",
          modal: "true"
        }}
        ariaHideApp={false}
      >
        {announceTitle ? (
          <LiveMessage message={announceTitle} aria-live="polite" />
        ) : (
          <div>
            {title && <LiveMessage message={title} aria-live="polite" />}
          </div>
        )}
        <ModalTitleBar
          id={id}
          title={title}
          closeModal={closeModal}
          fixed={fixed}
          copyText={copyText}
        />
        <ModalBody
          id="modal-body"
          className={`modal-content-wrapper${fixed ? " fixed-header" : ""}${
            copyText ? " with-copy-link" : ""
          }`}
        >
          {copyText ? (
            <div className="modal-body">
              {this.props.children}
              <ButtonWrapper className="modal-buttons">
                {copyText.length && <CopyButton text={copyText} />}
              </ButtonWrapper>
            </div>
          ) : (
            this.props.children
          )}
        </ModalBody>
      </Modal>
    );
  }
}

ModalWrapper.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  shouldCloseOnOverlayClick: PropTypes.bool,
  fixed: PropTypes.bool,
  copyText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  modalClass: PropTypes.string,
  overlayClass: PropTypes.string,
  onRequestClose: PropTypes.func,
  children: PropTypes.node,
  announceTitle: PropTypes.string
};

export default ModalWrapper;
